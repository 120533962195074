import Styled from 'styled-components';

export const ListItem = Styled.li`
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    color: #555;
    box-shadow: 2px 2px 2px #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .btns {
        margin-right: 10px;
    }

    :hover {
        background-color: #fafafa;
        transition: 0.5s;
    }

    .task-btn {
        border: none;
        outline: none;
        background-color: transparent;
        color: #777;
        opacity: 0.4;
        cursor: pointer;
        margin: 2px;
        padding: 5px;
        font-size: 0.8em;
    } .task-btn:hover {
        opacity: 1;
        transition: 1s;
    } .task-btn:active {
        transform: scale(0.8);
        transition: 0.5s;
    }

    .user {
        border: 1px solid #999;
        border-radius: 100px;
        font-size: 0.25em;
        padding: 5px;
        text-transform: uppercase;
    } .user:hover {
        background-color: #999;
        color: #fff;
        font-weight: 800;
    }

    .task-details {
        display: flex;
        color: #555;
        font-weight: 800;
        align-items: center;
    }

    .task-id {
        text-align: center;
        margin-right: 10px;
        border-radius: 100px;
        padding: 3px;
        height: 20px;
        width: 20px;
        font-size: 0.1em;
        color: #ccc;
        font-weight: 800;
    } .task-id:hover {
        background-color: #ccc;
        color: #fff;
        transition: 0.5s;
    }

    @media only screen and (max-width: 800px) {
        border-top: none;
        border-bottom: 1px solid #eee;
        border-radius: none;
        padding: 10px;
        margin: 0;
        box-shadow: none;

        .task-details {
            font-size: 0.9em;
            color: #333;
        }

        .btns {
            margin: 0;
            padding: 0;
            width: 100px;
            display: flex;
            justify-content: space-evenly;
        }

        .user { display: none }

        .task-btn {
            width: 25px;
            height: 25px;
            margin: 0;
            padding: 0;
        }
    }
`