import React from 'react';
import Styled from 'styled-components';
import ReactEmoji from 'react-emoji';

const Style = Styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: 700;

    .messageContainer {
        display: flex;
        justify-content: flex-end;
        padding: 0 5%;
        margin-top: 3px;
    }

    .messageBox {
        border: 1px solid #FF7FA0;
        border-radius: 15px;
        padding: 5px 20px;
        color: white;
        display: inline-block;
        max-width: 80%;
        margin-bottom: 5px;
    }

    .messageText {
        width: 100%;
        letter-spacing: 0;
        float: left;
        padding: 5px;
        font-size: 1.1em;
        word-wrap: break-word;
    }

    .messageText img {
        vertical-align: middle;
    }

    .sentText {
        display: flex;
        align-items: center;
        font-family: Helvetica;
        color: #828282;
        font-size: 0.5em;
        opacity: 0.7;
        letter-spacing: 0.3px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .pr-10 {
        padding-right: 10px;
    }

    .justifyStart {
        justify-content: flex-start;
    }

    .justifyEnd {
        justify-content: flex-end;
    }

    .colorWhite {
        color: white;
    }

    .colorDark {
        color: #353535;
    }

    .backgroundDark {
        background: #F60D57;
    }

    

    .backgroundLight {
        background: transparent;
    }

    @media only screen and (max-width: 800px) {
        font-size: 0.8em;

        .messageBox {
            padding: 2.5px;
        }
    }
`

const Message = ({ message: { user, text }, name }) => {
    let isSentByCurrentUser = false; 

    const trimmedName = name.trim().toLowerCase();
    const emojiText = ReactEmoji.emojify(text);

    if (user === name) {
        isSentByCurrentUser = true;
    }

    return (
        isSentByCurrentUser
        ? (
            <Style>
                <div className="messageContainer justifyEnd">
                    <p className="sentText pr-10">{trimmedName}</p>
                    <div className="messageBox backgroundDark">
                        <p className="messageText colorWhite">{emojiText}</p>
                    </div>
                </div>
            </Style>
        )
        : (
            <Style>
                <div className="messageContainer justifyStart">
                    <div className="messageBox backgroundLight">
                        <p className="messageText colorDark">{emojiText}</p>
                    </div>
                    <p className="sentText pl-10">{user}</p>
                </div>
            </Style>
        )
    )
}

export default Message;