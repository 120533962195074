import React, { useState, useEffect, useRef } from 'react';
import TodoList from './TodoList/TodoList';
import ChatBox from './ChatBox/ChatBox';
import io from 'socket.io-client';
import queryString from 'query-string';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Navbar from './Navigation/Navbar.jsx';
import Header from './Header';
import Footer from './Navigation/Footer.jsx';
import { DashboardWrapper } from './DashboardWrapper';
import { v1 as uuid } from 'uuid';

let socket;

const Dashboard = ({ location }) => {
    const ENDPOINT = 'https://sheltered-badlands-26952.herokuapp.com/'
    const { username } = queryString.parse(location.search);
    
    //STATE MANAGEMENT
    const [ input, setInput ] = useState('');
    const [ messages, setMessages ] = useState([]);
    const [ todos, setTodos ] = useState([]);

    //MOBILE SCROLL RESISTANCE
    const inputElement = useRef(null);

    useEffect(() => {
        inputElement.current.onfocus = () => {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        };
    });

    //JOIN EVENT
    useEffect(() => {
        //Initialize Client Side Socket
        socket = io(ENDPOINT);

        socket.emit('join', { username, room: 'Dashboard' }, err => {
            if (err) {
                alert(err);
            }
        })
    }, [ ENDPOINT, username ])

    //MESSAGE EVENT
    useEffect(() => {
        // on Message Emit from server
        socket.on('message', (message) => {
            setMessages([...messages, message]);
        })
    }, [ messages ])

    //TODO EVENT -> Needs to Interact w/ Context
    useEffect(() => {
        // on Todo Emit from server
        socket.on('todo', (todo) => {
            setTodos([...todos, todo]);
        })
    }, [ todos ])

    const sendMessage = e => {
        e.preventDefault();
        if (input) {
            socket.emit('sendMessage', input, () => {
                setInput('');
            });
        }
    }

    const addTodo = e => {
        e.preventDefault();
        if (input) {
            socket.emit('addTodo', input , () => {
                setInput('')
            })
        }
    }

    const removeTask = id => {
        setTodos(todos.filter(todo => todo.id !== id))
    }
    
    const toggleChat = e => {
        e.preventDefault();
        const taskManager= document.querySelector('.taskManager');
        const chat=document.querySelector('.chat');
        taskManager.style.display="none";
        chat.style.display="block";
        document.querySelector('.chat-btns').style.display="grid";
        document.querySelector('.taskManager-btns').style.display="none";
    }

    const toggleTask = e => {
        e.preventDefault();
        const taskManager= document.querySelector('.taskManager');
        const chat=document.querySelector('.chat');
        chat.style.display="none"
        taskManager.style.display="block";
        document.querySelector('.chat-btns').style.display="none";
        document.querySelector('.taskManager-btns').style.display="grid";
    }

    const usersInRoom = 8;

    return (
        <DashboardWrapper>
            <Navbar />
            <div className="top">
                <Card className="taskManager left half">
                    <Header title="task Manager" button="clear" />
                    <div className="scrollable-content">
                        <TodoList 
                            removeTask={ removeTask }
                            todos={ todos } 
                            name={ username }
                        />
                    </div>
                </Card>
                
                <Card className="chat right half">
                    <Header title="Chat" button={`Participants: ${usersInRoom}`}/>
                    <div className="scrollable-content">
                        <ChatBox className="chat" messages={ messages } name={ username }/>
                    </div>
                </Card>
            </div>
            <Card className="masterForm p20">
                <TextField 
                        multiline
                        autoFocus
                        className="masterInput"
                        placeholder="Type Input"
                        onChange={ e => setInput(e.target.value)}
                        ref={inputElement}
                        value={ input }
                        required
                />
                <div className="buttons default-btns">
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={ e => addTodo(e) }>
                        Create Task +
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={ e => sendMessage(e) }>
                        Send Message
                    </Button>
                </div>
                <div className="buttons chat-btns">
                    <Button 
                        className="faded"
                        variant="contained"
                        color="primary"
                        onClick={ toggleTask }>
                        Go To Tasks
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={ e => sendMessage(e) }>
                        Send Message
                    </Button>
                </div>
                <div className="buttons taskManager-btns">
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={ e => addTodo(e) }>
                        Add Todo +
                    </Button>
                    <Button
                        className="faded"
                        variant="contained"
                        color="secondary"
                        onClick={ toggleChat }>
                        See Messages
                    </Button>
                </div>
            </Card>
            <Footer className="footer"/>
        </DashboardWrapper>
    )
}

export default Dashboard;