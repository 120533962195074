import React from 'react';
import { ListItem } from './ListItem';

const Todo = ({ data, display_id, removeTask }) => {
    let { user, text, id } = data;

    if (user.length > 10) {
        user = user.substring(0, 10) + "...";
    }

    if (text.length > 50) {
        text = text.substring(0, 50) + "...";
    }

    return (
        <ListItem>
            <div className="task-details">
                <span className="task-id">{ display_id + 1 }</span>
                <span>{ text }</span>
            </div>
            <div className="btns">
                <button className="task-btn">
                    <span className="user">{ user }</span>
                </button>
                <button 
                    onClick={ () => removeTask(id) }
                    className="task-btn">
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
        </ListItem>
    )
}

export default Todo;