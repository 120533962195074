import React from 'react';
import Styled from 'styled-components';

const Wrapper = Styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
    color: #777;
    padding: 10px 25px;
    background-color: #f9f9f9;

    .title {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5em;
    }

    .btn {
        border: 1px solid #ddd;
        text-transform: capitalize;
        width: 125px;
        height: 30px;
        font-size: 1em;
        color: #777;
        font-weight: 800;
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        opacity: 0.7;
    } .btn:hover {
        opacity: 1;
        transition: 0.5s;
    } .btn:active {
        transform: scale(0.98);
        background-color: #eee;
        transition: 0.25s;
    }

    @media only screen (max-width: 800px) {}
`

const Header = ({ title, button }) => {
    return (
        <Wrapper>
            <h1 className="title">{ title }</h1>
            <button className="btn">{ button }</button>
        </Wrapper>
    )
}

export default Header;