import React from 'react';
import Styled from 'styled-components';
import { Logo } from '../../App';
import { Link } from 'react-router-dom';

const Navigation = Styled.div`
    background-color: #3F51B5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    h3 {
        text-transform: uppercase;
        color: #eee;
        opacity: 0.4;
        padding: 5px;
        font-size: 0.8em;
    }

    @media only screen and (max-width: 800px) {
        display: none;
    }
`

const Footer = () => {
    return (
        <Navigation>
            <h3>Elevenate studios &copy; 2020 | All Rights Reserved</h3>
        </Navigation>
    )
}

export default Footer;