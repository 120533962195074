import React, { useEffect, useRef } from 'react';
import Todo from './Todo';
import Styled from 'styled-components';

const Empty = Styled.div`
    display: flex;
    align-items: center;
    color: #ccc;
    font-size: 1.5em;
    height: 80%;
    justify-content: center;
`

const TodoList = ({ todos, removeTask }) => {

    const End = useRef(null);
    const scrollToEnd = () => {
        End.current.scrollIntoView({ behavior: 'smooth' })
    }
    
    useEffect(scrollToEnd, [ todos ])

    return (
        todos && todos.length > 0
        ? (
            <>
                {todos.map((todo, i) => (
                    <div key={i}>
                        <Todo 
                            data={todo} 
                            display_id={i}
                            removeTask={ removeTask }
                        />
                    </div>
                ))}
                <div ref={ End } />
            </>
        ) : (
            <>
                <Empty>No Current Tasks</Empty>
                <div ref={ End } />
            </>
        )
    )
}

export default TodoList;