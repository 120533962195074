import React, { useEffect, useRef } from 'react';
import Message from './Message';

const ChatBox = ({ messages, name }) => {
    const End = useRef(null);
    const scrollToEnd = () => {
        End.current.scrollIntoView({ behavior: 'smooth' })
    }
    
    useEffect(scrollToEnd, [ messages ])

    return (
        <>
            { messages.map((message, i) => (
                <div key={i}>
                    <Message
                        message={message}
                        name={name}
                    />
                </div>
            ))}
            <div ref={ End } />
        </>
    )
}

export default ChatBox;