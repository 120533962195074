import React from 'react';
import Styled from 'styled-components';
import { Logo } from '../../App';
import { Link } from 'react-router-dom';

const Navigation = Styled.div`
    background-color: #3F51B5;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 50px;
    justify-content: space-between;
    border-bottom: 1px solid #4255BD;

    .logo {
        font-size: 2em;
        text-shadow: 1px 2px 2px #000;
        padding: 5px;
    }

    .nav-links {
        display: flex;
    }

    h3 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: #eee;
        height: 50px;
        width: 80px;
        cursor: pointer;
        opacity: 0.5;
        font-size: 0.8em;
    } h3:hover {
        opacity: 1;
        font-size: 0.9em;
        background-color: rgba(0,0,0,0.2);
        transition: 0.7s;
    }
    
    @media only screen and (max-width: 800px) {
        justify-content: center;
        .nav-links {
            display: none;
        }

    }
`

const Navbar = () => {
    return (
        <Navigation>
            <Link to='/'>
                <Logo className="logo">Mingo</Logo>
            </Link>

            <div className="nav-links">
                <Link to='/'>
                    <h3>Overview</h3>
                </Link>

                <Link to='/'>
                    <h3>Pricing</h3>
                </Link>

                <Link to='/'>
                    <h3>Login</h3>
                </Link>
            </div>
        </Navigation>
    )
}

export default Navbar;