import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Styled from 'styled-components';
import { Logo } from '../App';

const Wrapper = Styled.div`
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5269EA;

    hr {
        width: 75%;
        opacity: 0.25;
    }

    .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .subtitle {
        color: #4051B5;
        margin: 20px;
        text-transform: uppercase;
        font-size: 0.8em;
    }

    .p25 { padding: 25px; }
    .p50 { padding: 50px; }

    form {
        display: flex;
        align-items: baseline;
    }

    form button {
        margin: 10px;
        font-weight: 800;
    }

    @media only screen and (max-width: 800px) {
        font-size: 1.5em;

        .p50 {
            padding: 5px;
        }

        .logo {
            font-size: 5em;
        }

        form {
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 200px;
            padding-bottom: 20px;
        } 

        .username { width: 250px; }

        button {
            width: 300px;
            height: 100px;
            border-radius: 1px;
            font-size: 1.2em;
        }
    }

    @media only screen and (max-width: 600px) {
        .login {
            padding: none;
        }

        .p50 {
            padding: 0;
        }

        .p25 {
            padding: 0;
        }

        .logo {
            padding: 50px 0 25px 0;
            font-size: 3em;
        }
    }

    @media only screen and (max-width: 600px) {
        .username {
            width: 200px;
        }

        .subtitle {
            font-size: 0.7em;
        }

        button {
            width: 225px;
        }
    }
`

const Login = () => {

    const [ userName, setUserName ] = useState('');

    return (
        <Wrapper>
            <Card className="login p50 flex">
                <Logo className="logo p25">Mingo</Logo>
                <hr/>
                <Typography className="subtitle"variant="h6">Realtime Collaboration</Typography>
                <form 
                    className="p25"
                    autoComplete="off">
                    <TextField
                        required={ true }
                        autoFocus={ true }
                        className="username"
                        required
                        placeholder="Username"
                        value={ userName }
                        onChange={ e => setUserName(e.target.value)}
                    />
                    <Link to={`/Dashboard?username=${userName}`}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >Login</Button>
                    </Link>
                </form>
            </Card>
        </Wrapper>
    )
}

export default Login;