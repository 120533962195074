import Styled from 'styled-components';

export const DashboardWrapper = Styled.div`
min-height: 100vh;
max-height: 100vh;
display: flex;
flex-direction: column;
justify-content: space-between;
background-color: #5269EA;

.chat,
.chat-btns,
.taskManager,
.taskManager-btns { display: none; }

.buttons {
    font-weight: 800;
}

button {
    margin-left: 10px;
    font-weight: 800;
}

.scrollable-content {
    padding: 5px;
    width: 100%;
    height: 450px;
    overflow: auto;
}

.masterForm {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 22.5px 0;
    padding: 25px;
}

.masterInput {
    width: 50%;
    max-height: 70px;
    overflow: auto;
}

.top {
    display: flex;
    justify-content: space-between;
}

.right { margin: 0 25px 0 12.5px  }

.left { margin: 0 12.5px 0 25px }

.half {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 50%;
    height: 65vh;
}

@media only screen and (max-width: 800px) {
    flex-direction: column;
    font-size: 1em;
    max-height: 75vh;
    
    .chat { display: block }
    .chat-btns {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    
    .taskManager,
    .default-btns,
    .taskManager-btns { 
        display: none;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }

    .scrollable-content {
        padding: 25px 0 0 0;
        width: 100%;
    }

    .header {
        width: 95%;
    }

    .header {
        position: static;
        font-size: 1em;
        padding: 0;
    }

    .top {
        flex-direction: column;
        align-items: center;
    }

    .half {
        width: 95%;
        margin: 5px;
    }

    .masterForm {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .masterInput {
        padding-top: 10px;
        height: 80px;
        max-height: 80px;
        width: 300px;
        margin-bottom: 10px;
        overflow: auto;
    }

    button {
        height: 75px;
        margin: 0;
        border-radius: 0;
    }

    .faded {
        opacity: 0.7;
    }
}
`