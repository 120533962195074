import React from 'react';
import Dashboard from './components/Dashboard';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Login from './components/Login';
import CssBaseline from '@material-ui/core/CssBaseline';
import Styled from 'styled-components';

export const Logo = Styled.h1`
  font-family: 'Satisfy', cursive; 
  color: #F60D57;
  font-size: 7rem;
  text-shadow: 2px 4px 4px #000;
`

const Wrapper = Styled.div``

const App = () => {
  return (
    <Wrapper>
      <CssBaseline />
      <Router>
        <Route exact path="/" component={ Login } />
        <Route path="/dashboard" component={ Dashboard } />
      </Router>
    </Wrapper>
  )
}

export default App;